<template>
  <div>
    <inhabitant-form
      v-model="form.inhabitant"
      :errors="errors.inhabitant"
      @update-prop="updateValue(`inhabitant.${$event}`, value)"
    />
    <v-row v-for="(el, key) in form.items" :key="key" dense>
      <v-col cols="11">
        <checkin-line
          v-model="form.items[key]"
          :errors="((errors || {}).items || [])[key]"
          @input="emitter"
          @update-prop="updatePropWrapPrefix(...arguments, `items.${key}`)"
        />
      </v-col>
      <v-col class="d-flex justify-center align-center">
        <v-btn :disabled="!validLines" icon @click="remove(key)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <v-btn outlined color="primary" dark @click="createLine">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('ITEM.ADD') }} {{ $t('PERSONS.ITEM') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InhabitantForm from '@/modules/inhabitant_changes/components/forms/InhabitantForm';
import formEditable from '@/mixins/form-editable';
import CheckinLine from '@/modules/inhabitant_changes/components/forms/CheckinLine';

export default {
  name: 'MassCheckin',
  components: { CheckinLine, InhabitantForm },

  mixins: [formEditable],

  data: () => ({
    form: {},
  }),

  computed: {
    validLines() {
      return this.form.items.length > 1;
    },
  },

  methods: {
    createLine() {
      this.form.items.push({
        person: {},
        personal_profile: {},
        user: {},
      });
    },

    remove(idx) {
      if (this.validLines) {
        this.form.items.splice(idx, 1);
      }
    },
  },
};
</script>
