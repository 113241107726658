<template>
  <v-card outlined dense class="px-2">
    <v-row dense>
      <v-col cols="12" sm="3">
        <v-select
          v-model="form.person.salutation_id"
          :items="salutations"
          :clearable="!readonly"
          item-text="long_name"
          item-value="id"
          :label="$t('PROFILE.SALUTATION')"
          :readonly="readonly"
          :error-messages="(errors.person || {}).salutation"
          @change="updateValue('person.salutation_id', $event)"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="form.person.first_name"
          :label="`${$t('PROFILE.FIRST_NAME')} *`"
          :rules="rules.first_name"
          :readonly="readonly"
          :error-messages="(errors.person || {}).first_name"
          @input="updateValue('person.first_name', $event)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="form.person.last_name"
          :label="`${$t('PROFILE.LAST_NAME')} *`"
          :rules="rules.last_name"
          :readonly="readonly"
          :error-messages="(errors.person || {}).last_name"
          @input="updateValue('person.last_name', $event)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          v-model="form.person.gender_id"
          :items="genders"
          item-text="name"
          item-value="id"
          :clearable="!readonly"
          :label="$t('PROFILE.GENDER')"
          :readonly="readonly"
          :error-messages="(errors.person || {}).gender"
          @change="updateValue('person.gender_id', $event)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="3">
        <v-select
          v-model="form.person.language_id"
          :items="languages"
          item-value="id"
          item-text="name"
          :label="$t('PROFILE.LANGUAGE')"
          :readonly="readonly"
          :error-messages="(errors.person || {}).language_id"
          @change="updateValue('person.language_id', $event)"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="3">
        <v-text-field
          v-model="birthday"
          v-mask="'##.##.####'"
          :error-messages="(errors.person || {}).birthday"
          hint="##.##.####"
          :label="$t('PROFILE.BIRTH_DATE')"
          append-icon="mdi-calendar"
          :clearable="!readonly"
          :rules="rules.birthday"
          :readonly="readonly"
          @click:clear="clearBirthday"
          @input="updateValue('person.birthday', $event)"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="3">
        <v-text-field
          v-model="form.user.email"
          :label="`${$t('USERS.EMAIL')}`"
          :rules="rules.email"
          :error-messages="(errors.user || {}).email"
          @input="updateValue('user.email', $event)"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="3">
        <v-select
          v-model="form.personal_profile.marital_status_id"
          :items="marital_statuses"
          item-text="name"
          item-value="id"
          :clearable="!readonly"
          :label="$t('PROFILE.MARITAL_STATUS')"
          :readonly="readonly"
          :error-messages="(errors.personal_profile || {}).marital_status"
          @change="updateValue('marital_status_id', $event)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="4">
        <phone-input
          v-model="form.personal_profile.mobile_phone"
          :label="$t('PROFILE.MOBILE_PHONE')"
          :readonly="readonly"
          :error-messages="(errors.personal_profile || {}).mobile_phone"
          @input="updateValue('mobile_phone', $event)"
        ></phone-input>
      </v-col>
      <v-col cols="12" sm="4">
        <phone-input
          v-model="form.personal_profile.phone"
          :label="$t('PROFILE.PHONE')"
          :readonly="readonly"
          :error-messages="(errors.personal_profile || {}).phone"
          @input="updateValue('phone', $event)"
        ></phone-input>
      </v-col>
      <v-col cols="12" sm="4">
        <v-textarea
          v-model="form.personal_profile.add_info"
          :counter="1500"
          outlined
          rows="1"
          :rules="rules.textDescription"
          :error-messages="(errors.inhabitant || {}).add_info"
          :label="$t('ITEM.ADDITIONAL_INFO')"
          @input="updateValue('inhabitant.additional_info', $event)"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import formEditable from '@/mixins/form-editable';
import { isEmail, maxLength, notEmpty } from '@/rules';
import i18nRefreshData from '@/mixins/i18nRefreshData';
import { mask } from 'vue-the-mask';
import moment from 'moment';
import { mapCacheActions } from 'vuex-cache';

export default {
  name: 'CheckinLine',
  components: {
    PhoneInput: () => import('@/components/phone/PhoneInput'),
  },
  directives: {
    mask,
  },
  mixins: [formEditable, i18nRefreshData],

  data() {
    return {
      form: {
        person: {
          birthday: null,

          first_name: null,
          gender: { id: null, name: null },

          language: {
            id: null,
            language_id: null,
            name: null,
            description: null,
          },
          language_id: 1,
          last_name: null,

          reminder_address: null,
          salutation: { id: null, name: null },
          status: { id: null, name: null, color: null },
        },
        personal_profile: { marital_status: { id: null, name: null } },
      },

      rules: {
        textDescription: [maxLength(1500)],
        email: [isEmail],
        first_name: [notEmpty],
        last_name: [notEmpty],
        birthday: [this.validateBirthday],
      },

      formatDate: 'DD.MM.YYYY',
      statuses: [],
      genders: [],
      salutations: [],
      birthday: null,
      languages: [],
      marital_statuses: [],
    };
  },

  mounted() {
    this.refreshData();
  },

  created() {
    this.birthday = this.form.person.birthday
      ? moment(this.form.person.birthday, 'YYYY-MM-DD').format(this.formatDate)
      : null;
  },

  methods: {
    ...mapCacheActions('inhabitant_change', ['getSalutations', 'getGenders']),
    ...mapCacheActions('localization', ['retrieveLanguages']),
    ...mapCacheActions('inhabitant_change', ['getMaritalStatuses']),

    validateBirthday() {
      if (this.birthday) {
        if (
          !moment(this.birthday, this.formatDate, true).isValid() ||
          !moment(this.birthday, this.formatDate).isBetween(
            '1900-01-01',
            moment()
          )
        ) {
          return this.$t('MESSAGE.INVALID_DATE');
        }
        this.form.person.birthday = moment(
          this.birthday,
          this.formatDate
        ).format('YYYY-MM-DD');
        return true;
      }
      this.form.person.birthday = '';
      return true;
    },
    clearBirthday() {
      this.birthday = null;
      this.form.person.birthday = null;
    },

    refreshData() {
      this.getSalutations().then(({ data }) => {
        this.salutations = data;
      });
      this.getGenders().then(({ data }) => {
        this.genders = data;
      });
      this.retrieveLanguages().then((response) => {
        this.languages = response.data;
      });
      this.getMaritalStatuses().then(({ data }) => {
        this.marital_statuses = data;
      });
    },
  },
};
</script>

<style scoped></style>
