<template>
  <v-card class="checkin-sheet" flat dense>
    <v-card-title v-if="!!Object.keys(form.data.items).length">
      <div class="d-inline-flex align-baseline">
        <span class="text-h5"
          >{{ $t('ITEM.CREATE') }}
          {{ $t('INHABITANT_CHANGES.REQUEST').toLowerCase() }}</span
        >
      </div>
      <v-spacer></v-spacer>

      <v-btn icon @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text dense>
      <v-form ref="createForm" v-model="isValid">
        <mass-checkin
          v-model="form.data"
          :errors="errors"
          @update-prop="updateValue($event, value)"
        ></mass-checkin>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <slot name="buttons" />
    </v-card-actions>
  </v-card>
</template>

<script>
import formEditable from '@/mixins/form-editable';
import MassCheckin from '@/modules/inhabitant_changes/components/forms/MassCheckin';

export default {
  name: 'CreateCheckinCard',
  components: { MassCheckin },

  mixins: [formEditable],

  data: () => ({
    isValid: true,
    form: {
      data: {
        items: [
          {
            person: {},
            personal_profile: {},
            user: {},
          },
        ],
      },
    },
  }),

  watch: {
    isValid() {
      this.$emit('isValid', this.isValid);
    },
  },

  methods: {
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.checkin-sheet {
  .col-12 {
    padding: 6px;
  }
}
</style>
